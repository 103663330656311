/**
 * An array of directive objects used to handle custom directives in a Vue.js application.
 *
 * @type {Array<Object>}
 * @property {string} name - The name of the custom directive.
 * @property {Directive} directive - The directive object itself, containing lifecycle hooks.
 * @property {Function} directive.mounted - Hook called when the directive is mounted on an element.
 * @property {Directive} directive.unmounted - Hook called when the directive is unmounted from an element.
 */
export const directives = [
    {
        name: 'track-event',
        directive: {
            mounted(el, binding) {
                el.addEventListener('click', () => {
                    const elementType = el.tagName.toLowerCase(); // Detect the element type, e.g., button, div
                    const action = binding.value.action || `${elementType}_click`; // Default action based on element type
                    const label = binding.value.label || el.innerText || el.textContent; // Default label is the inner text of the element

                    if (window.gtag) {
                        window.gtag('event', action, {
                            event_category: binding.value.category || 'Interaction',
                            event_label: label.trim(), // Trim whitespace from inner text
                            value: binding.value.value || 1
                        });
                    }
                });
            },
            unmounted(el) {
                el.removeEventListener('click');
            }
        }
    }
];