import {ValidationPlugin} from "@/plugins/validation.plugin";
import {createApp} from "vue";
import {createPinia} from "pinia";

import PrioApp from "@/entrypoints/PrioApp.vue";
import router from "@/entrypoints/prio.router";
import {initActionCable} from "@/services/action-cable.service";
import {directives} from "@/directives/index.directives";
import VueApexCharts from "vue3-apexcharts";

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import '@/css/app.prio.css';

// console.log("" +
//     "----------------------------------------------------------------------- \n" +
//     "                                                                        \n" +
//     "d8888b. d8888b. d888888b  .d88b.       db   db d888888b d8888b. d88888b \n" +
//     "88  `8D 88  `8D   `88'   .8P  Y8.      88   88   `88'   88  `8D 88'     \n" +
//     "88oodD' 88oobY'    88    88    88      88ooo88    88    88oobY' 88ooooo \n" +
//     "88~~~   88`8b      88    88    88      88~~~88    88    88`8b   88~~~~~ \n" +
//     "88      88 `88.   .88.   `8b  d8'      88   88   .88.   88 `88. 88.     \n" +
//     "88      88   YD Y888888P  `Y88P'       YP   YP Y888888P 88   YD Y88888P \n" +
//     "                                                                        \n" +
//     "----------------------------------------------------------------------- \n");


const pinia = createPinia()
const app = createApp(PrioApp)

app.use(router);
app.use(pinia);
app.use(ValidationPlugin);
app.component("apexchart", VueApexCharts);

app.mount("#app");

// register directives
for (let {name, directive} of directives) {
    app.directive(name, directive);
}


// app.config.errorHandler = (err) => {
//     console.error(err);
// }

// this will established websocket connection when it's load,
// at the time of subscription code don't needs to wait to open connection
initActionCable({debug: true});
