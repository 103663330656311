<script setup>
import {onBeforeUnmount, onMounted} from "vue";
import {useAnalytics} from "@/services/analytics.service";
import {useActionCable} from "@/services/action-cable.service";
import {CHANNELS} from "@/utils/constant";
import {useColorMode} from "@vueuse/core";
import {useToastStore} from "@/services/toast.service";
import {sleep} from "@/utils";
import NotificationComponent from "@/app/components/NotificationComponent.vue";
import {useRouter} from "vue-router";
import {ROUTE_NAMES} from "@/utils/routes.const";
import {useAuthStore} from "@/auth/auth.store";

const {initialize} = useAnalytics();
const {replace} = useRouter();
const auth = useAuthStore();
const {subscribe, unsubscribe, onReceived} = useActionCable(CHANNELS.APP);

useColorMode({
  emitAuto: true,
  selector: '#app',
  attribute: 'data-theme',
});

onMounted(async () => {
  subscribe();
  initialize({id: import.meta.env.GOOGLE_ANALYTICS_ID});
});

onBeforeUnmount(unsubscribe);

// onReceived(({status}) => {
//   if (status === 401) {
//     replace({name: ROUTE_NAMES.AUTH.LOGIN});
//   } else if (status === 201) {
//     replace({name: ROUTE_NAMES.HOME});
//   }
// }, "initialize_app");

</script>

<template>
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component"/>
    </transition>
  </router-view>
  <NotificationComponent/>
</template>

<style scoped>
</style>