<template>
  <div v-for="group in groupedToasts" :key="`toast-group-${group.position}`"
       class="fixed pointer-events-none z-50 w-full max-w-sm" :class="positionClasses(group.position)">
    <TransitionGroup
        name="toast"
        tag="div"
        class="flex flex-col gap-2">
      <div v-for="toast in group.toasts"
           :key="`toast-${toast.id}`"
           class="flex w-full max-w-sm mx-auto mb-4 overflow-hidden bg-white rounded-xl shadow-md">
        <div :class="[iconBgClass(toast.type)]"
             class="flex items-center justify-center w-12 min-w-12 min-h-12 text-white">
          <component :is="toast.component" color="white" stroke="1.8"/>
        </div>

        <div class="flex flex-row w-full">
          <div class="px-4 py-2 flex flex-col w-full">
            <p v-if="toast.title" class="font-semibold text-base">
              {{ toast.title }}
            </p>
            <p class="text-sm text-gray-600">
              {{ toast.message }}
            </p>
          </div>
          <button @click="removeToast(toast.id)" class="p-2 text-gray-500 hover:text-gray-700"
                  v-if="toast.duration < 0">
            <IconX size="18"/>
          </button>
        </div>

      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
import {computed} from 'vue';
import {useToastStore} from "@/services/toast.service";
import {storeToRefs} from "pinia";
import {IconX} from '@tabler/icons-vue';

const store = useToastStore();

// bg-success

const {toasts, removeToast} = storeToRefs(store);

const groupedToasts = computed(() => {
  return Object.entries(toasts.value.reduce((acc, toast) => {
    const position = toast.position; // Default position
    if (!acc[position]) {
      acc[position] = [];
    }
    acc[position].push(toast);
    return acc;
  }, {})).map(([position, toasts]) => ({position, toasts}));
});

const positionClasses = computed(() =>
    position => {
      switch (position) {
        case 'top-left':
          return 'top-1 left-1';
        case 'top-center':
          return 'top-1 left-1/2 -translate-x-1/2';
        case 'top-right':
          return 'top-1 right-1';
        case 'bottom-left':
          return 'bottom-1 left-1';
        case 'bottom-center':
          return 'bottom-1 left-1/2 -translate-x-1/2';
        case 'bottom-right':
          return 'bottom-1 right-1';
        default:
          return 'top-1 right-1';
      }
    });

const iconBgClass = computed(() => type => {
  switch (type) {
    case 'success':
      return 'bg-success';
    case 'error':
      return 'bg-error';
    case 'warning':
      return 'bg-warning';
    default:
      return 'bg-info';
  }
});
</script>

<style scoped>
.toast-enter-active, .toast-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.toast-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.toast-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.toast-leave-from {
  opacity: 1;
  transform: translateY(0);
}

.toast-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>