import {defineStore} from 'pinia';
import {useRouter} from "vue-router";

/**
 * Store for handling Google Analytics integration.
 */
export const useAnalytics = defineStore('x-google-analytics', {
    state: () => ({
        /**
         * Indicates if Google Analytics is initialized.
         * @type {boolean}
         */
        isInitialized: false,

        /**
         * Configuration for Google Analytics.
         * @type {Object}
         */
        config: {},

        /**
         * Enable or disable Google Analytics.
         * @type {boolean}
         */
        enable: true
    }),
    actions: {
        /**
         * Initialize Google Analytics with the given configuration.
         * @param {Object} config - Configuration object.
         */
        initialize(config = {}) {
            const defaultConfig = {
                id: null,
                properties: [],
                autoPageTracking: true,
                sendPageView: true,
                debug: false,
                consentMode: false,
            };

            this.config = {...defaultConfig, ...config};
            if (this.isInitialized || !this.config.id && this.config.properties.length === 0) {
                return;
            }

            const script = document.createElement('script');
            script.src = `https://www.googletagmanager.com/gtag/js?id=${this.config.id}`;
            script.async = true;
            document.head.appendChild(script);

            window.dataLayer = window.dataLayer || [];
            window.gtag = function () {
                window.dataLayer.push(arguments);
            };
            window.gtag('js', new Date());

            if (this.config.properties.length > 0) {
                this.config.properties.forEach(property => {
                    window.gtag('config', property.id, property.params || {});
                });
            } else {
                window.gtag('config', this.config.id);
            }

            if (this.config.autoPageTracking && this.config.sendPageView) {
                const router = useRouter();
                router.afterEach((to) => {
                    this.trackPageView(to);
                });
            }

            this.isInitialized = true;
            if (this.config.debug) {
                console.log('[Vue Analytics] Google Analytics initialized');
            }
        },

        /**
         * Track a page view in Google Analytics.
         * @param {RouteLocationNormalized} to - The path of the page to track.
         */
        trackPageView(to) {
            if (!this.isInitialized) return;
            window.gtag('config', this.config.id, {page_path: to.path, page_title: to.meta.title});
            if (this.config.debug) {
                console.log(`[Vue Analytics] Tracked page view: ${to.path} and ${to.meta.title}`);
            }
        },

        /**
         * Track an event in Google Analytics.
         * @param {string} action - The action of the event.
         * @param {string} category - The category of the event.
         * @param {string|null} [label=null] - The label of the event.
         * @param {number|null} [value=null] - The value of the event.
         */
        trackEvent(action, category, label = null, value = null) {
            if (!this.isInitialized) return;
            window.gtag('event', action, {
                event_category: category,
                event_label: label,
                value: value,
            });
            if (this.config.debug) {
                console.log(`[Vue Analytics] Event tracked: action=${action}, category=${category}, label=${label}, value=${value}`);
            }
        },

        /**
         * Opt-in to tracking in Google Analytics.
         */
        optIn() {
            if (!this.isInitialized) return;
            window.gtag('consent', 'update', {
                ad_storage: 'granted',
                analytics_storage: 'granted',
            });
            if (this.config.debug) {
                console.log('[Vue Analytics] User opted in to tracking');
            }
        },

        /**
         * Opt-out of tracking in Google Analytics.
         */
        optOut() {
            if (!this.isInitialized) return;
            window.gtag('consent', 'update', {
                ad_storage: 'denied',
                analytics_storage: 'denied',
            });
            if (this.config.debug) {
                console.log('[Vue Analytics] User opted out of tracking');
            }
        }
    }
});